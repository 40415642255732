import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logo-white.png"}) {
                publicURL
            }
            phoneIcon: file(relativePath: {eq: "icon_phone.svg"}) {
                publicURL
            }
            emailIcon: file(relativePath: {eq: "icon_envelope.svg"}) {
                publicURL
            }
            background: file(absolutePath: {glob: "**/content/services/new-builds/images/07.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            opengraph: file(relativePath: {eq: "open-graph.jpg"}) {
                publicURL
            }
        }
    `)

    const opengraphData = [
        {
            property: `og:image`,
            content: data.opengraph.publicURL
        }
    ]

    return (
        <>
            <Layout>
                <SEO title="Welcome to R. Fulcher & Sons"
                     meta={opengraphData}/>

                <section className="relative z-20 flex flex-col w-full h-full">
                    <div className="my-auto md:px-24 lg:px-40 container">
                        <div className="py-8">
                            <div className="bg-white shadow-holding text-center overflow-hidden">
                                <div className="bg-primary p-8 sm:p-10">
                                    <img src={data.logo.publicURL}
                                        alt="R. Fulcher & Sons Logo"
                                        className="block mx-auto w-48"/>
                                </div>

                                <div className="p-8 sm:p-10 xl:px-24">
                                    <h1 className="mb-3 leading-tight text-2xl md:text-3xl lg:text-4xl"><strong>R. Fulcher & Sons</strong></h1>
                                    <h2 className="mb-6 leading-snug text-lg md:text-xl lg:mb-8 lg:text-2xl">Over 100 years of professional rendering experience</h2>
                                    <p className="mb-6 leading-tight text-primary text-xl uppercase md:text-2xl lg:mb-8 lg:text-3xl"><strong>New website coming soon</strong></p>
                                    <ul className="mb-6 leading-relaxed list-disc list-inside lg:mb-8 lg:leading-loose">
                                        <li>A family run business established in 1809</li>
                                        <li>Specialists in traditional wall rendering and modern render systems</li>
                                        <li>Serving customers throughout Leigh-on-Sea, Hockley, Southend, Basildon, Benfleet, Rayleigh, Rochford, Thorpe Bay, Shoeburyness and Canvey Island</li>
                                    </ul>
                                    <p className="md:text-lg lg:text-xl"><strong>Contact us to find out more or request a quote:</strong></p>
                                </div>

                                <div className="bg-primary p-8 text-white sm:p-10">
                                    <ul className="text-white md:text-xl lg:text-2xl">
                                        <li className="flex flex-col justify-center items-center mb-6 leading-none sm:flex-row">
                                            <span className="flex items-center mb-3 sm:mb-0">
                                                <img src={data.phoneIcon.publicURL} alt="Call us now" className="inline mr-3 w-8"/>
                                                Call:
                                            </span>
                                            <strong className="pl-3"><a href="tel:01702478079" className="break-all">01702 478 079</a></strong>
                                            <span className="inline-block m-2 sm:my-0">or</span>
                                            <strong><a href="tel:+447791354259" className="break-all">07791 354 259</a></strong>
                                        </li>
                                        <li className="flex flex-col justify-center items-center leading-none sm:flex-row">
                                            <span className="flex items-center mb-3 sm:mb-0">
                                                <img src={data.emailIcon.publicURL} alt="Email us now" className="mr-3 w-8"/>
                                                Email:
                                            </span>
                                            <strong className="pl-3"><a href="mailto:rfulcherandsons@btinternet.com" className="break-all">rfulcherandsons@btinternet.com</a></strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="fixed top-0 left-0 z-10 bg-no-repeat bg-center bg-cover bg-fixed w-full h-full"
                     style={{backgroundImage: `url(${data.background.childImageSharp.fluid.src})`}}></div>

            </Layout>
        </>
    )
}

export default IndexPage
